import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import middlewarePipeline from "@/middleware/middleware-pipeline";
import user from "@/middleware/user";
import auth from '@/middleware/auth';
import { NavigationBar } from '@hugotomazi/capacitor-navigation-bar';
import { StatusBar } from '@capacitor/status-bar';

const Index = () => import('@/components/layouts/index.vue');
const Home = () => import('@/views/HomePage.vue');
const Login = () => import('@/views/LoginPage.vue');
const EmailResetPassword = () => import('@/views/EmailResetPassPage.vue');
const ResetPassword = () => import('@/views/ResetPasswordPage.vue');
const Register = () => import('@/views/RegisterPage.vue');
const RegisterConfirmed = () => import('@/views/RegisterConfirmed.vue');
const EmailVerified = () => import('@/views/EmailVerified.vue');
const Play = () => import('@/views/PlayPage.vue');
const ProgramDetails = () => import('@/views/ProgramDetailsPage.vue');
const Search = () => import('@/views/SearchPage.vue');
const Favorite = () => import('@/views/FavoritesPage.vue');
const UserSettingsPage = () => import('@/views/UserSettingsPage.vue');
const Radio = () => import('@/views/Radio.vue');
const CategoryPage = () => import('@/views/CategoryPage.vue');


const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/home'
  },
  {
    path: '/',
    name: 'Index',
    component: Index,
 
  children: [
    {
      path: '/login',
      name: 'Login',
      component: Login,
      meta: { title: 'Login', middleware: [user]}
    },
    {
      path: '/register',
      name: 'Registrazione',
      component: Register,
      meta: { title: 'Registrazione', middleware: [user]}
    },
    {
      path: '/register-confirmed',
      name: 'RegistrazioneConfermata',
      component: RegisterConfirmed,
      meta: { title: 'RegistrazioneConfermata', middleware: [user]}
    },
    {
      path: '/email-verified',
      name: 'Emailverificata',
      component: EmailVerified,
      meta: { title: 'Email Verificata', middleware: [user]}
    },
    {
      path: '/reset-pass',
      name: 'EmailResetPassword',
      component: EmailResetPassword,
      meta: { title: 'Reset Password', middleware: [user]}
    },
    {
      path: '/reset-pass-page/:token',
      name: 'ResetPasswordPage',
      component: ResetPassword,
      meta: { title: 'Reset Password', middleware: [user]}
    },
    {path: '/home', name: 'Home', component: Home, meta: { title: 'Home'}},
    {path: '/radio', name: 'Radio', component: Radio, meta: { title: 'Radio'}},

    {path: '/play/:contentId?', name: 'Player', component: Play, meta: { title: 'Play'}},
    {path: '/program/:programId?', name: 'Program', component: ProgramDetails, meta: { title: 'Program Details'}},
    {path: '/category/:id?', name: 'Category', component: CategoryPage, meta: { title: 'Category Page'}},

    {path: '/search/:searchText?', name: 'Search', component: Search, meta: { title: 'Search'}},
    {path: '/favorites', name: 'Favorites', component: Favorite, meta: { title: 'Search', middleware: [auth]}},
    {path: '/user-profile', name: 'UserProfile', component: UserSettingsPage, meta: { title: 'UserSettingsPage', middleware: [auth]} },
  ]
},
    {
      path: '/:pathMatch(.*)*',
      redirect: '/',
    }
  ]


export  const router = createRouter({ 
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  /*if (from.fullPath.includes('play')) {
    NavigationBar.show();
    StatusBar.show();
    StatusBar.setOverlaysWebView({ overlay: false });
    window.screen.orientation.lock('portrait');
  }*/
 if (!to.meta.middleware) {
  return next()
}

  const middleware: any = to.meta.middleware;
  const context = {
  to,
  from,
  next,
  //store  | You can also pass store as an argument
  }

  return middleware[0] ? middleware[0]({
      ...context,
      next:middlewarePipeline(context, middleware,1)
  }) : null;
})

export default router
